// src/Form.js
import React, { useState } from 'react';
import { collection, addDoc } from 'firebase/firestore';
import { db } from './firebase';
import betalogo from '../src/assets/BETA-Logo1.png';
import androidlogo from './assets/AndroidiOS.png';
import weblogo from './assets/WebApp.jpg';
import "./App.css";


function MainPage() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await addDoc(collection(db, "inquiry"), {
        name: name,
        email: email,
        message: message,
        timestamp: new Date()
      });
      setSuccess(true);
      setName('');
      setEmail('');
      setMessage('');
    } catch (error) {
      console.error("Error adding document: ", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container">     

      {/* Header Section */}
      <header className="header">
        <h1>WE ARE B.E.T.A</h1>
        <strong><p>Where Software Meets Endless Possibilities</p></strong>
      </header>

      {/* Welcome Section */}
      <section className="section welcome">
        <div className="content">
          <h2>WELCOME TO B.E.T.A</h2>
          <strong><p>
            Breakthrough Engineering and Technology Applications. <br />
            One Stop Solution for Software Development & More.
          </p></strong>
          <p>
            At B.E.T.A, we provide innovative technology consulting, AI-powered solutions, 
            and data-driven strategies to businesses worldwide. With expertise in digital transformation, 
            automation, and business process optimization, we help companies in the United States, South East Asia, 
            Asia Pacific, and Europe unlock their full potential. Our solutions enhance efficiency, scalability, 
            and global market expansion through advanced data analysis, custom tech solutions and digital innovation.

          </p>

          {/* <p>
            We have delivered more than 15+ end-to-end products in the past 02
            years ranging from ERP, Accounting, Ecommerce, RPA and Custom made
            solutions.
          </p> */}
        </div>
        <img
          className="section-image"
          src={betalogo}
          alt="BETA Edge Logo"
        />
      </section>

      {/* What We Do Section */}
      <section className="section what-we-do">
        <img
          className="section-image"
          src={betalogo}
          alt="BETA Edge Logo"
        />
        <div className="content">
          {/* <h1>WHAT WE DO</h1> */}
          {/* <h1>Technology Consulting</h1>
          <p>
            We specialize in high-performance web and mobile applications,
            focusing on cross-platform and hybrid solutions. Our comprehensive
            services extend from digital marketing strategies to full-scale
            startup launches. We have got you covered.
          </p> */}
          
          <h1>Technology Consulting</h1>
          <p>
            Our technology consulting services drive digital innovation. 
            We deliver tailored strategies to modernize operations, streamline processes, 
            and implement emerging technologies. 
            Transform your business with our expert tech solutions.
          </p>

          <h1>AI-Powered Solutions</h1>
          <p>
            Leverage AI-powered solutions for smarter automation and decision-making. 
            We specialize in artificial intelligence development and machine learning, 
            helping businesses enhance efficiency and achieve rapid growth.
          </p>
          
          {/* <h1>Data-Driven Business Strategy</h1> */}
          <h1>Insight-Driven Growth Strategy</h1>
          <p>
            Maximize business performance with insight-driven growth strategies. 
            Utilizing advanced data analytics and predictive insights, 
            we empower organizations to make informed decisions, optimize operations, 
            and stay ahead in competitive markets. Our data-centric approach ensures 
            your business thrives through evidence-based decision-making and scalable growth solutions.
          </p>
        </div>
      </section>

      {/* Web Applications Section */}
      <section className="section web-applications">
        <div className="content">
          <h2>WEB APPLICATIONS</h2>
          <p>
            High-performance web applications for all-round needs, no matter the
            platform. These applications are designed to deliver seamless, fast,
            and reliable performance across all devices and operating systems.
          </p>
        </div>
        <img
          className="section-image-android-web"
          src={weblogo}
          alt="BETA Edge Logo"
        />
      </section>

      {/* Mobile Applications Section */}
      <section className="section mobile-applications">
        <img
          className="section-image-android-web"
          src={androidlogo}
          alt="BETA Edge Logo"
        />
        <div className="content">
          <h2>MOBILE APPLICATIONS</h2>
          <p>
            Cross Platform Mobile Applications with native-level performance for
            Android and iOS. Unlock the potential of your mobile apps with
            unparalleled speed and reliability.
          </p>
        </div>
      </section>



  <div className="contact-description">
    <p>
      We're here to help you bring your ideas to life. Whether you have a question,
      need support, or are ready to start your next big project, we are eager to
      assist. Reach out now and experience our commitment to excellence and innovation.
      Don't hesitate, let's create something amazing together!
    </p>
  </div>

      {/* Contact Section */}
      <section className="section contact">
        <h2>GET IN TOUCH</h2>
        <form className="contact-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <input type="text" id="name" placeholder="Name" 
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            />
          </div>
          <div className="form-group">
            <input type="email" id="email" placeholder="Email" 
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <textarea id="message" placeholder="Message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
            />
          </div>
          <button type="submit" disabled={loading}>
          {loading ? 'Submitting...' : 'Submit Query'}
          </button>
        </form>
        
        {/* Modal */}
        {success && <p>Message sent successfully!</p>}

        <p className="contact-info">
          <strong>B.E.T.A Edge Inc.</strong> <br/>
          <br/>
          <strong>Global & Innovation Center:</strong><br/>
         
          Kolkata, India. <br />
          <br/>
          <strong>Our Nexus Points:</strong>
          <br/>

          Buona Vista, Singapore. <br/>
          New Taipei City, Taiwan.<br/>
          Kowloon Tong, Hong Kong. <br/>
          <br/>
          <strong>Write to us:</strong> <br/>
          connect@beta-edge.com <br/>
        
          <strong>Dial us:</strong> <br/>
          (+65) 9167 3307. <br/>
          {/* (+91) 9804 619 202. */}
        </p>
      </section>

	  <footer className="footer">
	  <p>&copy; 2024 B.E.T.A Edge Inc. All rights reserved.</p>
	  </footer>

    </div>
  );
}

export default MainPage;
